<template>
    <loading-animation class="m-2" v-if="loading"></loading-animation>
    <div v-else>
        <b-card class="m-4" v-if="details">
            <div class="row mb-2">
                <div class="col font-weight-bold">Order Details</div>
                <div class="col text-right">
                    <b-btn variant="primary" size="sm" @click="changeStatus">Change Status</b-btn>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-4">
                    <div class="mb-1">User</div>
                    <span class="text-muted" v-if="details.user">{{ details.user }}</span>
                    <span class="text-muted" v-else>Nil</span>
                </div>
                <div class="col-md-4">
                    <div class="mb-1">Created Date</div>
                    <span class="text-muted" v-if="details.created_date">{{ details.created_date }}</span>
                    <span class="text-muted" v-else>Nil</span>
                </div>
                <div class="col-md-4">
                    <div class="mb-1">Pin Code</div>
                    <span class="text-muted" v-if="details.pin_code">{{ details.pin_code }}</span>
                    <span class="text-muted" v-else>Nil</span>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-4">
                    <div class="mb-1">Phone Number</div>
                    <span class="text-muted" v-if="details.phone">{{ details.phone }}</span>
                    <span class="text-muted" v-else>Nil</span>
                </div>
                <div class="col-md-4">
                    <div class="mb-1">Order Status</div>
                    <span class="text-muted" v-if="details.order_status">{{ details.order_status }}</span>
                    <span class="text-muted" v-else>Nil</span>
                </div>
                <div class="col-md-4">
                    <div class="mb-1">Updated Date</div>
                    <span class="text-muted" v-if="details.updated_date">{{ details.updated_date }}</span>
                    <span class="text-muted" v-else>Nil</span>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-md-4">
                    <div class="mb-1">Amount</div>
                    <span class="text-muted" v-if="details.order_amount">₹ {{ details.order_amount }}</span>
                    <span class="text-muted" v-else>Nil</span>
                </div>
                <div class="col-md-4">
                    <div class="mb-1">Delivery Charge</div>
                    <span class="text-muted" v-if="details.delivery_charge">₹ {{ details.delivery_charge }}</span>
                    <span class="text-muted" v-else>Nil</span>
                </div>
                <div class="col-md-4">
                    <div class="mb-1">Total Amount</div>
                    <span class="text-muted" v-if="details.total_amount">₹ {{ details.total_amount }}</span>
                    <span class="text-muted" v-else>Nil</span>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="mb-1">Address</div>
                    <textarea class="text-muted" v-if="details.address" disabled
                              style="background-color: white; height: 15vh; width: 100%" v-model="details.address"></textarea>
                    <span class="text-muted" v-else>Nil</span>
                </div>
                <div class="col-md-4">
                    <div class="mb-1">Alternate Number</div>
                    <span class="text-muted" v-if="details.land_phone">{{ details.land_phone }}</span>
                    <span class="text-muted" v-else>Nil</span>
                </div>
                <div class="col-md-4">
                    <div class="mb-1">E-Mail</div>
                    <span class="text-muted" v-if="details.email">{{ details.email }}</span>
                    <span class="text-muted" v-else>Nil</span>
                </div>
            </div>
        </b-card>
        <b-card class="m-4 pb-3" v-if="orderItems.length!==0">
            <b-card-title class="font-weight-bold">
                Order Items
            </b-card-title>
            <simple-table :fields="fields" :data="orderItems"></simple-table>
        </b-card>
        <b-modal id="changeStatusModal" title="Update Order Status" modal-class="modal-right" size="sm"
                 hide-footer>
            <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                    <div class="alert alert-danger" v-if="errorMessage" v-html="errorMessage"></div>
                    <div class="alert alert-info" v-if="infoMessage" v-html="infoMessage"></div>
                    <b-row>
                        <b-col>
                            <label class="mb-2">Change Status</label>
                            <validated-select name="Change Status"
                                              :options="statusOption"
                                              :rules="{required: true}" :show-blank="true"
                                              v-model="model.status"></validated-select>
                        </b-col>
                    </b-row>
                    <br>
                    <loading-button class="float-right" variant="primary" loading-text="Updating..." text="Update"
                                    :saving="loading"></loading-button>
                </b-form>
            </validation-observer>
        </b-modal>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name: 'OrderDetailsPage',
    data () {
        return {
            id: this.$route.params.id,
            details: '',
            orderItems: [],
            loading: false,
            loading1: false,
            errorMessage: '',
            infoMessage: '',
            fields: [
                {
                    name: 'id',
                    sortField: 'id',
                    title: 'ID'
                },
                {
                    name: 'order',
                    sortField: 'order',
                    title: 'Order'
                },
                {
                    name: 'order_item',
                    sortField: 'order_item',
                    title: 'Item'
                },
                {
                    name: 'order_item_quantity',
                    sortField: 'order_item_quantity',
                    title: 'Order Item Quantity'
                },
                {
                    name: 'item_price',
                    sortField: 'item_price',
                    title: 'Item Price'
                }
            ],
            model: {
                status: '',
                order_id: ''
            },
            statusOption: [
                { label: 'Order Placed', value: 'Order Placed' },
                { label: 'Processing', value: 'Processing' },
                { label: 'Cancelled', value: 'Cancelled' },
                { label: 'Expired', value: 'Expired' },
                { label: 'Packed', value: 'Packed' },
                { label: 'Out For Delivery', value: 'Out For Delivery' },
                { label: 'Delivered', value: 'Delivered' }
            ]
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods: {
        changeStatus () {
            this.model.order_id = this.details.id;
            this.$bvModal.show('changeStatusModal');
        },
        loadDetails () {
            const that = this;
            that.loading = true;
            axios.form(urls.admin.orders.details, { order_id: that.id }).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.details = json.order;
                    that.orderItems = json.order_items.data;
                    that.loading = false;
                } else {
                    that.$notify('Error');
                }
            });
        },
        onSubmit () {
            const formValidator = this.$refs.formValidator;
            this.loading1 = true;
            const component = this;
            axios.form(urls.admin.orders.changeStatus, component.model).then(function (data) {
                const json = data.data;
                if (json.error === false) {
                    component.$notify(json.message, 'success',
                        {
                            type: 'success'
                        });
                    component.details.order_status = json.status;
                    component.$bvModal.hide('changeStatusModal');
                } else {
                    formValidator.setErrors(json.errors);
                    component.errorMessage = json.errors.all || '';
                    component.$notify(json.errors, 'Error',
                        {
                            type: 'warning'
                        }
                    );
                }
                component.loading1 = false;
            }).catch(function () {
                component.loading1 = false;
                component.$notify('Something went wrong..!', 'Error',
                    {
                        type: 'warning'
                    }
                );
            });
        }
    }
};
</script>

<style scoped>
.text-small {
    font-weight: 500;
}
</style>
