<template>
    <div>
        <div class="p-4">
            <b-card>
                <div class="row">
                    <div class="col-2">
                        <b-card-title style="margin-bottom: 45px">
                            Orders
                        </b-card-title>
                    </div>
                    <div class="col-8">
                    </div>
                    <div class="col-2">
                        <label>Status</label>
                        <b-form-select name="Order Status"
                                              :options="statusOption"
                                              v-model="status"></b-form-select>
                    </div>
                </div>
                <vue-table :pageSizes="[10, 20, 50, 100]" ref="table" :url="apiBase" :fields="fields" :perPage="10" :extra-params="{status}">
                    <template slot="actions" slot-scope="props">
                        <div class="btn-group">
                            <b-btn variant="primary" size="sm" @click="viewDetails(props.rowData)">
                                <b-icon-eye></b-icon-eye>
                                View
                            </b-btn>
                        </div>
                    </template>
                </vue-table>
                <b-modal id="orderDetails" title="Order Details" :no-close-on-backdrop="true" modal-class="modal-right"
                         size="lg" hide-footer>
                    <order-details-page :detail="detail_id"></order-details-page>
                </b-modal>
            </b-card>
        </div>
    </div>
</template>

<script>
import urls from '../../../data/urls';
import OrderDetailsPage from './OrderDetails';

export default {
    name: 'ListOrders',
    components: { OrderDetailsPage },
    data () {
        return {
            apiBase: urls.admin.orders.list,
            detail_id: '',
            status: '',
            fields: [
                {
                    name: 'id',
                    sortField: 'id',
                    title: 'ID'
                },
                {
                    name: 'user',
                    sortField: 'user',
                    title: 'User'
                },
                {
                    name: 'created_date',
                    sortField: 'created_date',
                    title: 'Created Date'
                },
                {
                    name: 'updated_date',
                    sortField: 'updated_date',
                    title: 'Updated Date'
                },
                {
                    name: 'order_status',
                    sortField: 'order_status',
                    title: 'Order Status'
                },
                {
                    name: '__slot:actions',
                    title: '',
                    titleClass: 'center aligned text-right',
                    dataClass: 'aligned text-right'
                }
            ],
            statusOption: [
                { text: '', value: '' },
                { text: 'Order Placed', value: 'Order Placed' },
                { text: 'Processing', value: 'Processing' },
                { text: 'Cancelled', value: 'Cancelled' },
                { text: 'Expired', value: 'Expired' },
                { text: 'Packed', value: 'Packed' },
                { text: 'Out For Delivery', value: 'Out For Delivery' },
                { text: 'Delivered', value: 'Delivered' },
                { text: 'Payment Failed', value: 'Payment Failed' }
            ]
        };
    },
    methods: {
        viewDetails (rowData) {
            this.$router.push('/admin/order/' + rowData.id + '/details');
        }
    }
};
</script>

<style scoped>

</style>
